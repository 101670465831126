import * as React from 'react'
import { WindowSizeProvider } from 'components/WindowSizeContext'
import { getContent } from '../js/queries'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { Scrollable, useProgress } from 'components/Scrollable'
import { motion, useTransform } from 'framer-motion'
import { VerticalWrapper } from '../components/VerticalWrapper'
import { PausePlay } from 'components/PausePlay'
import { useSize } from '../js/hooks'
import { useIntroContext } from 'components/IntroContext'
import { Cursor } from 'components/Cursor'

const toHtml = str => documentToHtmlString(JSON.parse(str))
  .replace(/\n/g, `</br>`)

const AboutInner = () => {

  const container = React.useRef(null)
  const [containerHeight, setContainerHeight] = React.useState(0)
  const [offset, setOffset] = React.useState(0)

  const { size } = useSize()
  const { about } = getContent()
  const { progress, startPlayProgress } = useProgress()

  const linneaText = toHtml(about.linneaText.raw)

  const sliderStyle = {
    y: useTransform(progress,
      [0, 1], ['0%', '-100%']
    )
  }

  React.useEffect(() => {

    if (container.current) {
      setContainerHeight(container.current.clientHeight)
    }

    startPlayProgress()

    return progress.onChange(v => {
      setOffset(Math.floor(v))
    })

  }, [size])

  return (
    <div ref={container} className="about">

      <PausePlay />

      <motion.div className="about__inner" style={sliderStyle}>

        <VerticalWrapper parentHeight={containerHeight}>

          <div className="about__info">

            <img className="about-text__desktop" src="/about_large.svg" />

            <img className="about-text__mobile" src="/about_small.svg" />

            {/* <p>
              {about.aboutText.aboutText}
            </p> */}

          </div>

        </VerticalWrapper>

        <VerticalWrapper parentHeight={containerHeight}>

          <ul className="about__contact">

            <li>

              <span>E-mail</span>

              <br />

              <a href={`mailto:${ about.email }`}>{about.email}</a>

            </li>

          </ul>

        </VerticalWrapper>

        <VerticalWrapper parentHeight={containerHeight}>

          <ul className="about__people">

            <li dangerouslySetInnerHTML={{ __html: linneaText }} />

          </ul>

        </VerticalWrapper>

        <VerticalWrapper parentHeight={containerHeight}>

          <p className="about__social">Follow us on <a href={about.instagram}>Instagram</a> or <a href={about.vimeo}>Vimeo</a></p>

        </VerticalWrapper>

        <VerticalWrapper parentHeight={containerHeight}>

          <div className="about__clients">

            <h2>Selected Clients</h2>

            <ul>

              {about.clients.map(client => {

                const { url } = client.logo.file

                return (
                  <li key={url}>
                    <img src={url} />
                  </li>
                )

              })}

            </ul>

          </div>

        </VerticalWrapper>

      </motion.div>

      <div className="about__gradient" />

    </div>
  )

}

// Collaborators section
//
// <VerticalWrapper parentHeight={containerHeight}>
//
//   <div className="about__collaborators">
//
//     <h2>Collaborators</h2>
//
//     <ul>
//
//       {about.collaborators.map(collab => (
//         <li key={collab.name}>
//           {collab.name}
//         </li>
//       ))}
//
//     </ul>
//
//   </div>
//
// </VerticalWrapper>

const AboutPage = (props) => {

  const { setIsAbout, setShowCursor, showCursor } = useIntroContext()

  React.useEffect(() => {
    setIsAbout(true)
    setShowCursor(true)
  }, [setIsAbout, setShowCursor])

  return (
    <WindowSizeProvider>
      <Scrollable>
        <AboutInner />
        <Cursor />
      </Scrollable>
    </WindowSizeProvider>
  )

}

export default AboutPage
